import styles from "../css/home.module.css";
import centre from "../assets/Centre_Text.png";
import { Footer, OrganizerCard } from "../components";
import Carousel from "../components/Carousel/Carousel";
import Waves from "../components/Waves/waves";
import { useRef, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import EventsNav from "../components/EventsNav";
import { useAuth } from "../hooks";
import { useLocation } from "react-router-dom";
import { getSingleDoc } from "../config/firebaseFirestore";
import Button from "../assets/";

const OPTIONS = { loop: true };

const Home = () => {
  const ref = useRef();
  const auth = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [organizers, setOrganizers] = useState([]);

  const fetchData = async () => {
    const organizerData = await getSingleDoc("WebContentsNew", "NewQueries");
    let organizers = [];

    for (let i = 0; i < organizerData.data.length; i++) {
      switch (organizerData.data[i].Dept.content) {
        case "Hospitality and Transport":
          organizers.push(organizerData.data[i]);
          break;
        default:
          console.log("Unknown Flag");
          break;
      }
    }

    setOrganizers(organizers);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className={styles.home}>
      <div className={styles.header}>
        {!auth.user ? (
          location.pathname === "/login" ? null : (
            <button
              style={{ position: "absolute", right: "40px", top: "30px" }}
              className={styles.button1}
              onClick={() => {
                navigate("/login");
              }}
            >
              𝐋𝐨𝐠𝐢𝐧
            </button>
          )
        ) : (
          <>
            {location.pathname !== "/" ? (
              ""
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  position: "absolute",
                  top: "30px",
                  right: "40px",
                }}
              >
                <button
                  className={styles.button1}
                  onClick={() => {
                    navigate("/dashboard");
                  }}
                >
                  𝐃𝐚𝐬𝐡𝐛𝐨𝐚𝐫𝐝
                </button>
                <button
                  className={styles.button1}
                  onClick={() => {
                    auth.logout();
                  }}
                >
                  𝐋𝐨𝐠𝐨𝐮𝐭
                </button>
              </div>
            )}
          </>
        )}
        {/* <div className={styles.logo}>
          <img src={logo} alt="logo" />
        </div> */}
        <div className={styles.centre}>
          <div className={styles.centrebig}>
            <img src={centre} alt="centre" />
          </div>
          <div className={styles.centresmall}>
            <img src={centre} alt="centre" />
          </div>
          {/* <div>
            <p className={styles.date}>17𝓽𝓱-20𝓽𝓱 𝓞𝓬𝓽𝓸𝓫𝓮𝓻 </p>
          </div> */}
        </div>
        <div className={styles.text}>
          <div className={styles.inner}>
            <hr className={styles.hr1}></hr>
            <hr className={styles.hr2}></hr>
            <div className={styles.events}>EVENTS AND COMPETITIONS</div>
            <hr className={styles.hr3}></hr>
            <hr className={styles.hr4}></hr>
          </div>
        </div>
        <div
          className={styles.btn}
          onClick={() => {
            if (ref.current) {
              ref.current.scrollIntoView({ behavior: "smooth" });
            }
          }}
        >
          <img src={Button} style={{ marginTop: '10px' }} alt="" />
          
        </div>
        <Waves fill="url(#gradient)" className={styles.wave1} options={{
          height: 10,
          amplitude: 15,
          speed: 0.25,
          points: 12
        }}>
          <defs>
            <linearGradient id="gradient" gradientTransform="rotate(90)">
              <stop offset="0.6" stopColor="#1d1911" />
              <stop offset="1" stopColor="transparent" />
            </linearGradient>
          </defs>
        </Waves>
        <Waves fill="url(#gradient)" className={styles.wave2} options={{
          height: 10,
          amplitude: 20,
          speed: 0.15,
          points: 6
        }}>
          <defs>
            <linearGradient id="gradient" gradientTransform="rotate(90)">
              <stop offset="0.4" stopColor="#1d1911" />
              <stop offset="1" stopColor="#1d1911" />
            </linearGradient>
          </defs>
        </Waves>
      </div>
      <div className={styles.contactwrapper}>
        <div className={styles.eventsContainer} ref={ref}>
          <EventsNav />
          <Carousel slides={Array.from(Array(12).keys())} options={OPTIONS} />
        </div>
        <div className={styles.logoContainer}>
          <div className={styles.contact}>𝐂𝐨𝐧𝐭𝐚𝐜𝐭 𝐔𝐬</div>
        </div>
        <div className={styles.organizersSection}>
          {organizers.map((organizer, index) => (
            <OrganizerCard key={index} organizer={organizer} />
          ))}
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default Home;
